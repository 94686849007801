
import { Options, Vue } from 'vue-class-component';
import { AuthService } from '@/services/auth-service';

@Options({
  components: { },
})
export default class RunningGiveawaysView extends Vue {
 
}
