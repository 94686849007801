
import { Options, Vue } from 'vue-class-component';
import { AuthService } from '@/services/auth-service';
import InfoCard from '@/components/InfoCard.vue';

@Options({
  components: {InfoCard },
})
export default class HomeView extends Vue {
  
}
