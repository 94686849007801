
import { Options, Vue } from 'vue-class-component';
import { AuthService } from '@/services/auth-service';
import { TwitchAuthCodeResponse } from '@/models/twitch-auth-code-response.model';
import { nanoid } from 'nanoid';


@Options({
  components: { },
})
export default class DashboardView extends Vue {
  
}
