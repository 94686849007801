
import { Options, Vue } from 'vue-class-component';
import { AuthService } from '@/services/auth-service';
import { TwitchAuthCodeResponse } from '@/models/twitch-auth-code-response.model';
import { nanoid } from 'nanoid';


@Options({
  components: { },
})
export default class LoginView extends Vue {
  messages: any[] = [];
  authService = new AuthService();

  onLoginWithTwitchButtonPressed() {
    const stateRandomString = nanoid();

    this.$cookies.set('state', stateRandomString);

    window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=jli6odzqzcqw0jf463jhowq49cu3c3&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Ftwitchredirect&response_type=code&scope=channel%3Amanage%3Aredemptions+channel%3Aread%3Aredemptions+channel%3Aread%3Asubscriptions+channel%3Aread%3Avips+moderation%3Aread&state=${stateRandomString}`;
    // this.authService.loginWithTwitch('LOGIN_CODE_TEST').then(response => {
    //   console.log(response);
    //   if (response.status == 200) {
    //     this.$router.push('/twitchredirect');
    //   }
    // });
  }

  created() {
    console.log(this.$route.query)

    if (this.$route.query?.accessDenied) {
      this.messages.push({ severity: 'error', content: 'Para acessar o sistema, por favor conecte com a Twitch' });
    }

    if (this.$route.query?.invalidState) {
      this.messages.push({ severity: 'error', content: 'Houve um erro na validação do login, por favor tente novamente' });
    }
  }
}
