import { faTiktok, faTwitter, faInstagram, faTwitch, faYoutube, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faHandHoldingBox, faClockRotateLeft, faToolbox, faStopwatch, faHomeUser } from '@fortawesome/pro-duotone-svg-icons';

export const Icons = [
  faTiktok,
  faTwitch,
  faTwitter,
  faInstagram,
  faYoutube,
  faHandHoldingBox as IconDefinition,
  faClockRotateLeft as IconDefinition,
  faToolbox as IconDefinition,
  faStopwatch as IconDefinition,
  faHomeUser as IconDefinition
];