import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0660200"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-main-content" }
const _hoisted_2 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_card = _resolveComponent("info-card")!
  const _component_p_card = _resolveComponent("p-card")!
  const _component_scroll_panel = _resolveComponent("scroll-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_p_card, null, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_info_card, {
            class: "col",
            header: 'Sorteios finalizados',
            info: '0'
          }),
          _createVNode(_component_info_card, {
            class: "col",
            header: 'Sorteios em andamento',
            info: '0'
          }),
          _createVNode(_component_info_card, {
            class: "col",
            header: 'Total de ganhadores',
            info: '0'
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_scroll_panel)
  ]))
}