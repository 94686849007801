
import { Options, Vue } from 'vue-class-component';
import { AuthService } from '@/services/auth-service';
import { TwitchAuthCodeResponse } from '@/models/twitch-auth-code-response.model';

@Options({
  components: { },
})
export default class TwitchRedirect extends Vue {
  authService = new AuthService();

  created() {
    if (this.$route.query?.code) {
      let twitchCodeResponse = new TwitchAuthCodeResponse();
      twitchCodeResponse.code = this.$route.query.code as string;
      twitchCodeResponse.scopes = this.$route.query.scope as string;

      if (!this.$route.query?.state || this.$route.query?.state != this.$cookies.get('state')) {
        this.$router.push('/?invalidState=true');
      }
      else {
        this.$cookies.remove('state');

        this.authService.loginWithTwitch(twitchCodeResponse).then(response => {
          console.log(response);
          // TODO Redirecionar para o dashboard
          this.$router.push('/');
        })
        .catch(e => {
          console.log('Erro:', e);
        });
      }
    }
    else {
      this.$router.push('/?accessDenied=true');
    }
  }
}
