import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import TwitchRedirect from '@/views/TwitchRedirect.vue';
import DashboardView from '@/views/DashboardView.vue';
import HomeView from '@/views/dashboard/HomeView.vue';
import CreateGiveawayView from '@/views/dashboard/CreateGiveawayView.vue';
import RunningGiveawaysView from '@/views/dashboard/RunningGiveaways.vue';
import HistoryView from '@/views/dashboard/HistoryView.vue';
import SettingsView from '@/views/dashboard/SettingsView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/twitchredirect',
    name: 'twitchredirect',
    component: TwitchRedirect
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    redirect: { name: 'home'},
    children: [
      { path: '/home', name: 'home', component: HomeView },
      { path: '/create-giveaway', name:'create-giveaway', component: CreateGiveawayView },
      { path: '/running-giveaways', name:'running-giveaways', component: RunningGiveawaysView },
      { path: '/history', name:'history', component: HistoryView },
      { path: '/settings', name:'settings', component: SettingsView },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
