import { createApp } from 'vue';
import VueI18n from 'vue-i18n';
// import { messages, defaultLocale } from './resources/i18n';
import App from './App.vue';
import router from './router';
import i18n from './i18n'
import VueCookies from 'vue-cookies';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from './resources/fontawesome-icons'

// PrimeVue
import PrimeVue from 'primevue/config';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ScrollPanel from 'primevue/scrollpanel';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Card from 'primevue/card';

import Tweet from 'vue-tweet';


import SideMenu from './components/SideMenu.vue';
import InfoCard from './components/InfoCard.vue';

import '@/utils/string';

library.add(...Icons);

const app = createApp(App);
  
app.use(router)
   .use(PrimeVue)
   .use(i18n)
   .use(VueCookies);

app.component('font-awesome-icon', FontAwesomeIcon)
   .component('tab-view', TabView)
   .component('tab-panel', TabPanel)
   .component('scroll-panel', ScrollPanel)
   .component('p-card', Card)
   .component('p-button', Button)
   .component('p-message', Message)
   .component('Tweet', Tweet);

app.component('side-menu', SideMenu)
   .component('info-card', InfoCard);

app.mount('#app');