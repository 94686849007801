import axios, { AxiosInstance } from 'axios';

const httpClient: AxiosInstance = axios.create({
  // baseURL: process.env.API_BASE_URL,
  baseURL: 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default httpClient;